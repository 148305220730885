/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { NavLink, useLocation } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import cache from '../../../../_assets/images/cache.png';
import { useDispatch } from "react-redux";
import { setSearchAllV2 } from "../../../../../contractManagement/AppStore/ActionCreator/commonDataAction";
import SaleList, { SearchFormData } from "../../../../../appStore/actions/saleList";

export function AsideMenuList({ layoutProps }: any) {
  const dispatch = useDispatch();
  const location = useLocation();
  const getMenuItemActive = (url: string, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/home", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/home">
            <span className="svg-icon menu-icon">
              <i className="fas fa-home"></i>
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::2 Level*/}
        <li className={`menu-item ${getMenuItemActive("/businesses", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/businesses">
            <span className="svg-icon menu-icon">
              <i className="fas fa-tags"></i>
            </span>
            <span className="menu-text">Agency</span>
          </NavLink>
        </li>
        {/*end::2 Level*/}
        <li className={`menu-item ${getMenuItemActive("/agentprofile/false", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/agentprofile/false">
            <span className="svg-icon menu-icon">
              <i className="fas fa-user"></i>
            </span>
            <span className="menu-text">Agent</span>
          </NavLink>
        </li>
        {/*begin::3 Level*/}
        <li className={`menu-item ${getMenuItemActive("/consortium", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/consortium">
            <span className="svg-icon menu-icon">
              <i className="fas fa-project-diagram"></i>
            </span>
            <span className="menu-text">Consortium</span>
          </NavLink>
        </li>
        {/*end::3 Level*/}

        {/*begin::4 Level*/}
        <li className={`menu-item ${getMenuItemActive("/organizations", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/organizations">
            <span className="svg-icon menu-icon">
              <i className="fas fa-landmark"></i>
            </span>
            <span className="menu-text">Organisation</span>
          </NavLink>
        </li>


        <li className={`menu-item ${getMenuItemActive("/myfiles", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/myfiles">
            <span className="svg-icon menu-icon">
              <i className="fas fa-file"></i>
            </span>
            <span className="menu-text">All Files</span>
          </NavLink>
        </li>
        {/*end::4 Level*/}

        <hr style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />

        {/*begin::5 Level*/}
        <li className={`menu-item ${getMenuItemActive("/fees", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/fees">
            <span className="svg-icon menu-icon">
              <i className="fas fa-dollar-sign"></i>
            </span>
            <span className="menu-text">Fees Management</span>
          </NavLink>
        </li>
        {/*end::5 Level*/}

        {/*begin::6 Level*/}
        <li className={`menu-item ${getMenuItemActive("/taxes", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/taxes">
            <span className="svg-icon menu-icon">
              <i className="fas fa-file-invoice-dollar"></i>
            </span>
            <span className="menu-text">Tax Management</span>
          </NavLink>
        </li>
        {/*end::6 Level*/}

        <hr style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />

        {/*begin::7 Level*/}
        <li className={`menu-item ${getMenuItemActive("/promotions/loyalty", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/promotions/loyalty">
            <span className="svg-icon menu-icon">
              <i className="fas fa-bullhorn"></i>
            </span>
            <span className="menu-text">Loyalty Promotion</span>
          </NavLink>
        </li>
        {/*end::7 Level*/}
        {/*begin::8 Level*/}
        <li className={`menu-item ${getMenuItemActive("/promotions/sales", false)}`} aria-haspopup="true">
          {/* <NavLink className="menu-link" to="/promotions/sales">
            <span className="svg-icon menu-icon">
              <i className="fas fa-capsules"></i>
            </span>
            <span className="menu-text">Sales Promotion</span>
          </NavLink> */}

          {/* <NavLink className="menu-link" to="/promotions/new">
            <span className="svg-icon menu-icon">
              <i className="fas fa-plus-circle"></i>
            </span>
          </NavLink> */}
        </li>
        {/*end::8 Level*/}
        <hr style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />

        {/* <li className={`menu-item ${getMenuItemActive("/contractlist", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/contractlist">
            <span className="svg-icon menu-icon">
              <i className="fas fa-file-signature"></i>
            </span>
            <span className="menu-text">Contract Management
            </span>
          </NavLink>

        </li> */}
        <li className={`menu-item ${getMenuItemActive("/sunquest-cache-admin", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/sunquest-cache-admin">
            <span className="svg-icon menu-icon">
              <img src={cache} alt="cache" className="sunQuestMenuIcon" />
            </span>
            <span className="menu-text">Sunquest cache admin
            </span>
          </NavLink>

        </li>
        <li className={`menu-item ${getMenuItemActive("/Newcontractlist", false)}`} aria-haspopup="true" onClick={()=>dispatch(setSearchAllV2([]))}>
          <NavLink className="menu-link" to="/Newcontractlist">
            <span className="svg-icon menu-icon">
              <i className="fas fa-file-signature"></i>
            </span>
            <span className="menu-text">New Contract Management
            </span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/SalesPromotion", false)}`} aria-haspopup="true" onClick={() => {dispatch(SaleList([])); dispatch(SearchFormData({}));dispatch(setSearchAllV2([]))}}>
          <NavLink className="menu-link" to="/SalesPromotion">
            <span className="svg-icon menu-icon">
              <i className="fas fa-capsules"></i>
            </span>
            <span className="menu-text">New Sales Promotion
            </span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/FraudCheck", false)}`} aria-haspopup="true" onClick={() => dispatch(setSearchAllV2([]))}>
          <NavLink className="menu-link" to="/FraudCheck">
            <span className="svg-icon menu-icon">
              <i className="fa-solid fa-shield-halved"></i>
            </span>
            <span className="menu-text">Fraud Check List
            </span>
          </NavLink>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}