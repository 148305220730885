// @ts-nocheck
// import isFirstDayOfMonth from "date-fns/esm/isFirstDayOfMonth";
import { mealArr, assistanceArr, wheelChairArr, monthNames, FlightLogo } from "./Constant"
import moment from "moment";


export const convertCamelCase = (string) => {
  if (string && string.trim().length === "") return
  return string && string[0].toUpperCase() + string.slice(1).toLowerCase()

};

export const changeDateFormat = (date, format) => {
  if (date) {
    var d = new Date(date);
    let month = ("0" + (d.getMonth() + 1)).slice(-2);
    let day = ("0" + d.getDate()).slice(-2);
    let year = d.getFullYear();
    if (format === "dashed") return `${year}-${month}-${day}`
    if (format === "slash") return `${month}/${day}/${year}`
    if (format === "monthFormat") return `${d.getDate()} ${monthNames[d.getMonth()]} ${year}`
  } else {
    return ""
  }

};

export const getSpecificValueFromArr = (key, value, arrName, specificResultKey, specifiedArr) => {
  console.log("flight details",key, value, arrName, specificResultKey, specifiedArr)
  let arr = []
  if (arrName === "meal") arr = mealArr
  if (arrName === 'assistance') arr = assistanceArr
  if (arrName === 'wheelChair') arr = wheelChairArr
  if (arrName === "any") arr = specifiedArr

  let matchedObj = arr.find(obj => { return obj[key] === value })
  return matchedObj && matchedObj[specificResultKey]
}

export const getPrefix = (gender) => {
  if (gender === undefined) return ""
  if (gender === "Male" || gender === "male") return "Mr"
  if (gender === "Female" || gender === "female") return "Mrs"
}

export const titleCase = (str) => {

  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  let a = ""+ <br/> + "";
  
  let newStr = splitStr.join(' ')
  newStr = newStr.replace(/\n/g, a);
  console.log("splitStr", newStr)
  return newStr;
}

export const getCurrentWeek = () => {
  var curr_date = new Date();

  var day = curr_date.getDay();

  var diff = curr_date.getDate() - day + (day === 0 ? -6 : 1); // 0 for sunday

  var week_start_tstmp = curr_date.setDate(diff);

  var week_start = new Date(week_start_tstmp);

  var week_start_date = formattedDate(week_start);

  var week_end = new Date(week_start_tstmp);  // first day of week 

  week_end = new Date(week_end.setDate(week_end.getDate() + 6));

  var week_end_date = formattedDate(week_end);
  let dateobj = { from: week_start_date, to: week_end_date }
  return dateobj
}

export const formattedDate = (date) => {
  var m = ("0" + (date.getMonth() + 1)).slice(-2);
  var d = ("0" + date.getDate()).slice(-2);
  var y = date.getFullYear();
  return y + '-' + m + '-' + d + 'T00:00:00';
}

export const getCurrentMonth = () => {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  var monthStartDate = formattedDate(firstDay);
  var monthEndDate = formattedDate(lastDay);
  let dateobj = { from: monthStartDate, to: monthEndDate }
  return dateobj
}

export const formatNextDayTime = (HeaderDepartureDate,HeaderArrivalDate) => {

  let currentTime = moment('23:59', "HH:mm");
    let startTime = moment(moment.utc(HeaderDepartureDate).format("HH:mm"), "HH:mm");
    let endTime = moment(moment.utc(HeaderArrivalDate).format("HH:mm"), "HH:mm");

    let newStartTime = moment.utc(HeaderDepartureDate).format("ddd MMM DD, YYYY")
    let newEndTime = moment.utc(HeaderArrivalDate).format("ddd MMM DD, YYYY")
  
    currentTime.toString();
    startTime.toString();
    endTime.toString();
  
    if ((startTime.hour() >= 12 && endTime.hour() <= 12) || endTime.isBefore(startTime)) {
      endTime.add(1, "days");       // handle spanning days endTime
  
      if (currentTime.hour() <= 12) {
        currentTime.add(1, "days");       // handle spanning days currentTime
      }
    }

   let isBetween = currentTime.isBetween(startTime, endTime);
   var msDiff = new Date(newEndTime).getTime() - new Date(newStartTime).getTime();    //Future date - current date
   var difference = Math.floor(msDiff / (1000 * 60 * 60 * 24));
   
   return  isBetween ? <> {moment.utc(HeaderArrivalDate).format("HH:mm")} <sup className="text-danger">+{difference}</sup> </> : <> {moment.utc(HeaderArrivalDate).format("HH:mm")}</>

}

export const diffrenceInDatesCheck = (HeaderDepartureDate,HeaderArrivalDate) => {
 

  let currentTime = moment('23:59', "HH:mm");
  let startTime = moment(moment.utc(HeaderDepartureDate).format("HH:mm"), "HH:mm");
  let endTime = moment(moment.utc(HeaderArrivalDate).format("HH:mm"), "HH:mm");

  let newStartTime = moment.utc(HeaderDepartureDate).format("ddd MMM DD, YYYY")
  let newEndTime = moment.utc(HeaderArrivalDate).format("ddd MMM DD, YYYY")

  currentTime.toString();
  startTime.toString();
  endTime.toString();

  if ((startTime.hour() >= 12 && endTime.hour() <= 12) || endTime.isBefore(startTime)) {
    endTime.add(1, "days");       // handle spanning days endTime

    if (currentTime.hour() <= 12) {
      currentTime.add(1, "days");       // handle spanning days currentTime
    }
  }

 let isBetween = currentTime.isBetween(startTime, endTime);
 var msDiff = new Date(newEndTime).getTime() - new Date(newStartTime).getTime();    //Future date - current date
 var difference = Math.floor(msDiff / (1000 * 60 * 60 * 24));
 
 
 return  Number(difference)


 
}


export const range =(end,start) =>{

  
	var len = (end) - (start) + 1;
	var a = new Array(len);
  
	for (let i = 0; i < len; i++) a[i] = end - i;
	return a;
}

export const getFlightLogo = (data)=> {

  let logoLowerCase = data && data.toLowerCase();
  let flightLogo = `https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/${logoLowerCase}.png`
    return flightLogo
    
}

export const moveIndex=(input,from)=>{
  let newArr=[]
  if(input  && input.length>0){
    let index = input.findIndex(obj =>obj.title==="PENALTIES")
    if(index>0){
      let ele =  input.filter(obj =>obj.title==="PENALTIES")[0]
   
      if(ele && ele!==null){
        newArr = input.splice(0, 0, ele);
        return newArr
       
      } else{
        newArr = input
        return newArr
      }
    }
   
  
  }
   
}

export const getFlightArr=(arr)=>{

  let newFlightArr=[]

  arr &&
  arr.map((obj) => {
    Object.values(obj).map((data) => {
      newFlightArr = [...newFlightArr, ...data.flights];
    });
  });

 return newFlightArr

}

export const getFlightSrrData=(newFlightArr)=>{

  let flightsArr=[]

  newFlightArr.map((flightObj, index) => {

    flightsArr = [
      ...flightsArr,
      {
        from: flightObj.departureAirport,
        to: flightObj.arrivalAirport,
        flightNumber: flightObj && flightObj.number,
        meal: {},
        assistance: {},
        wheelChair: {},
        carrier: flightObj.carrier,
        code: flightObj.statusDetail && flightObj.statusDetail.code
      },
    ];
  });
  
 return flightsArr

}

export const splitFromCapital =(str)=>{
  if(str===undefined) return
  let result = str.split(/(?=[A-Z])/);
  if(result.length===1){
    return str
  } else{
    str = result.join(" ")
    console.log("str",str)
    return str
    
  }
 
}

export const DateZoneConvertion = (date) => {

 let resultDate = moment(date).tz("America/Toronto").format("DD MMM YYYY")

return resultDate

}

export const TimeZoneConvertion = (date) => {

  let resultDate = moment(date).tz("America/Toronto").format("HH:mm")
 
 return resultDate
 
 }

 export const endTimeDate = (date) => {
  var m = ("0" + (date.getMonth() + 1)).slice(-2);
  var d = ("0" + date.getDate()).slice(-2);
  var y = date.getFullYear();
  return y + '-' + m + '-' + d + 'T23:59:59';
}