//@ts-nocheck
import React, { useEffect, useState } from 'react';
import Bookingpnrlist from '././Bookingpnrlist';
import calender from "../bookingConfirmation/Images/calender.svg";
import { useSelector, useDispatch } from "react-redux";
import {setFlightTypeCheck} from '../bookingConfirmation/ActionCreator/searchForm';
import { getCurrentWeek, formattedDate, getCurrentMonth, changeDateFormat, endTimeDate } from "../bookingConfirmation/Utils/CommenFunction"
import { getMyFiles } from "../bookingConfirmation/thunks/getMyFiles"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import Collapse from 'react-bootstrap/Collapse'
import { CSVLink, CSVDownload } from "react-csv";
import ReactExport from "react-export-excel";
import { Select } from '@material-ui/core';
import Workbook from 'react-excel-workbook'
import { Tabs, Tab } from "react-bootstrap";
import moment from 'moment';
import { setMyFilesData } from '../bookingConfirmation/ActionCreator/myFiles';

export default function Searchbooking(props) {
  const flightType1 = useSelector((state) => state.searchForm.flightType);
  const myFiles = useSelector((state) => state.myFile.myFilesData);
  const [myFilesData, setMyFiles] = useState([])
  const [selectOption, setSelectOption] = useState("Today")
  const [datePeriod, setDatePeriod] = useState({ from: formattedDate(new Date()), to: formattedDate(new Date()) })
  const [filterOption, setFilterOption] = useState("booking")
  const [isFilterChnaged, setFilterChanges] = useState(false)
  const [customDate, setCustomDate] = useState({ from: formattedDate(new Date()), to: formattedDate(new Date()) })
  const [open, setOpen] = useState(false)
  const [openD, setOpenD] = useState(false)
  const [bookingCode, setStatusCode] = useState("All")
  const dispatch = useDispatch()
  const orgId = useSelector((state) => state.agentProfile.orgId);
  const agencyId = useSelector((state) => state.agentProfile.agencyId);
  const agencyGroupId = useSelector((state) => state.agentProfile.agencyGroupId);
  const [isResult, setResult] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [flightType, setFlightType] = useState("CAD") 
  let backupOrgId = localStorage.getItem("orgId");
  const sortByBookingDate = (value) => {
    
    setMyFiles([])
    setResult(false)
    setFilterOption("booking")
    getFilteredData("booking", datePeriod.from, datePeriod.to ,value)

  }


  const sortByDepartureDate = () => {
    setMyFiles([])
    setResult(false)
    setFilterOption("departure")
    getFilteredData("departure", datePeriod.from, datePeriod.to)


  }

  const handleSelect = (e) => {
    setSelectOption(e)
    setMyFiles([])
    setResult(false)
    let dateObj = {}
    setOpen(false)
    if (e === "Today") {
      let date = formattedDate(new Date())
      dateObj.from = date
      dateObj.to = date
      setDatePeriod(dateObj)
      getFilteredData(filterOption, date, date,flightType)
    }

    if (e === "Yesterday") {
      var currentDate = new Date();
      var yesterdayDate = currentDate.setDate(currentDate.getDate() - 1);
      let date = formattedDate(new Date(yesterdayDate))
      dateObj.from = date
      dateObj.to = date
      setDatePeriod(dateObj)
      getFilteredData(filterOption, dateObj.from, dateObj.to ,flightType)
    }
    if (e === "This Week") {
      dateObj = getCurrentWeek()
      setDatePeriod(dateObj)
      getFilteredData(filterOption, dateObj.from, dateObj.to ,flightType)
    }

    if (e === "This Month") {
      dateObj = getCurrentMonth()
      setDatePeriod(dateObj)
      getFilteredData(filterOption, dateObj.from, dateObj.to,flightType)
    }
    if (e === "customDate") {
      setCustomDate({ from: changeDateFormat(new Date(), "dashed"), to: changeDateFormat(new Date(), "dashed") })     
    }

  }

  const getFilteredData = async (option, fromDate, toDate,flightType) => {
    
    let from = option ? option + "FromDate" : filterOption + "FromDate"
    let to = option ? option + "ToDate" : filterOption + "ToDate"
    let requestPayload = {}
    setIsLoading(true)

    requestPayload = {
      organizationId: orgId !== "" ? orgId : backupOrgId,
      [from]: fromDate,
      [to]: endTimeDate(new Date(toDate)),
    }



    let myfilesResult = await dispatch(getMyFiles(requestPayload ,flightType))
    if (myfilesResult) {
      let data = myfilesResult.bookings;
      let myfilesResults = {}
      if (bookingCode !== 'All') {
        let bookings = data.filter(list => list.bookingStatusDetail.code == bookingCode);
        myfilesResults.bookings = bookings;
      } else {
        myfilesResults = myfilesResult
      }
      setIsLoading(false)

      if (option == "departure") {
        setMyFiles(myfilesResults && myfilesResults?.bookings && myfilesResults.bookings.sort((a, b) => { return new Date(b.departureDate) - new Date(a.departureDate) }))
        setResult(true)
      }
      if (option == "booking") {
        setMyFiles(myfilesResults && myfilesResults?.bookings && myfilesResults.bookings.sort((a, b) => { return new Date(b.bookingDate) - new Date(a.bookingDate) }))
        setResult(true)
      }


    }

  }


  const handleDateChange = (date, key) => {
    setSelectOption("customDate")
    let obj = { ...customDate, [key]: changeDateFormat(date, "dashed") }
    setDatePeriod(obj)
    setCustomDate({ ...customDate, [key]: changeDateFormat(date, "dashed") })
  }

  const openCollapse = () => {
    setOpen(!open)
  }

  const openCollapseForD = () => {
    setOpenD(!openD)
  }

  useEffect(() => {
    setMyFiles(myFiles)
  }, [myFiles])

  const getCustomDateData = (option, customDate) => {

    let formatedFromDate = formattedDate(new Date(customDate.from))
    let formatedToDate = formattedDate(new Date(customDate.to))
    let dateObj = {}
    dateObj.from = formatedFromDate
    dateObj.to = formatedToDate
    setDatePeriod(dateObj)
    getFilteredData(option, formatedFromDate, formatedToDate,flightType)
  }
  const setBookingCode = (code) => {
    setStatusCode(code)
  }

  useEffect(() => {
    setMyFiles([])
    if (props.keyName === "sortby") {
      sortByBookingDate("CAD")
    }
  }, [props.keyName])


  const csvData = [
    ["Booking Date", "PNR", "Booking Status", "Trip Type", "Cabin Type", "Total Sell Amount", "Loyalty Points"],

  ];

  myFilesData.map((data, i) => {
    let mysortdata = data.bookingStatusDetail.code
    if (bookingCode == 'All') {
      let cabinType = data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin == undefined ? "" : data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin

      let arry = [data.bookingDate, data.bookingDetails.recordLocator, data.bookingStatusDetail.description
        , data.tripType, cabinType, data?.bookingDetails?.itineraryFare?.totalAmount?.amount, data?.promotion?.loyaltyPoints]
      csvData.push(arry)
    }

    if (mysortdata === bookingCode) {
      let cabinType = data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin == undefined ? "" : data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin

      let arry = [data.bookingDate, data?.bookingDetails?.recordLocator, data?.bookingStatusDetail?.description
        , data.tripType, cabinType, data?.bookingDetails?.itineraryFare?.totalAmount?.amount, data?.promotion?.loyaltyPoints]
      csvData.push(arry)
    }
  })



  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const excelArry = []
  myFilesData.map((data, i) => {
    let mysortdata1 = data.bookingStatusDetail.code
    if (bookingCode == 'All') {
      let cabinType = data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin == undefined ?
        " " : data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin
      let dataObj = {
        bookingDate: data?.bookingDate,
        recordLocator: data?.bookingDetails?.recordLocator,
        description: data?.bookingStatusDetail?.description,
        tripType: data?.tripType,
        cabinType: cabinType,
        amount: data?.bookingDetails?.itineraryFare?.totalAmount?.amount,
        loyaltyPoints: data?.promotion?.loyaltyPoints
      }
      excelArry.push(dataObj)
    }
    if (mysortdata1 === bookingCode) {
      let cabinType = data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin == undefined ?
        " " : data.bookingDetails?.itineraryFare?.paxFares[0]?.fareBasisDetails[0]?.cabin
      let dataObj = {
        bookingDate: data?.bookingDate,
        recordLocator: data?.bookingDetails?.recordLocator,
        description: data?.bookingStatusDetail?.description,
        tripType: data?.tripType,
        cabinType: cabinType,
        amount: data?.bookingDetails?.itineraryFare?.totalAmount?.amount,
        loyaltyPoints: data?.promotion?.loyaltyPoints
      }
      excelArry.push(dataObj)
    }
  })

  const setFilghtTypeFunction = (value) => {
    sortByBookingDate(value)
    setFlightType(value)
    dispatch(setMyFilesData([]))
    }

  return (
    <>
      <div className=" searchbyinput" style={{ position: "relative" }}>
        {/* <div className="pnrstatus">
          <div className="form-check">
            <input className="form-check-input" type="radio" name="bookingState" checked={filterOption === "booking" && "checked"} id="inlineRadio5" value="booking" onChange={sortByBookingDate} />
            <label className="form-check-label" for="inlineRadio5"  >By Reservation Dates</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="bookingState" id="inlineRadio6" checked={filterOption === "departure" && "checked"} value="departure" onChange={sortByDepartureDate} />
            <label className="form-check-label" for="inlineRadio6" >By Departure Dates</label>
          </div>

        </div> */}

        <div className="col-md-6 cm-col">
            {/* <label className="cm-head-label">Select</label> */}
            <div className="cm-react-radio-inline">
            <label >
                <input
                  type="radio"
                  value={flightType}
                  onChange={() => {setFilghtTypeFunction("CAD")}}
                  checked = {flightType == "CAD"}
                />
                CAD
              </label>
            <label>
                <input
                  type="radio"
                  value={flightType}
                  onChange={() => {setFilghtTypeFunction("USD")}}
                  checked = {flightType == "USD"}
                />
                USD
              </label>
            </div>
          </div>
        <div className="datewiseview">
          <div className="bd-pi-formcol pi-formcol-2 mf-filter-row" style={{ flex: 1 }}>

            <>
            
              <span className="mf-filter-label">Show Bookings Done</span>
              <button
                onClick={openCollapse}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="mf-filter-btn"
              > <img src={calender} />
                {/* {selectOption} */}
                {selectOption === "customDate" ? "Custom Date" : selectOption}
              </button>
              <Collapse in={open}>
                <div id="example-collapse-text" className="mf-filter-collapse">

                  <div onClick={() => handleSelect("customDate")}><span className="dropditem">Custom Date</span></div>
                  <div onClick={() => handleSelect("Today")} ><span className="dropditem">Today</span></div>
                  <div onClick={() => handleSelect("Yesterday")} ><span className="dropditem">Yesterday</span></div>
                  <div onClick={() => handleSelect("This Week")} ><span className="dropditem">This Week</span></div>
                  <div onClick={() => handleSelect("This Month")} ><span className="dropditem">This Month</span></div>
                </div>
              </Collapse>
            </>
          </div>


          <div className=" pnrbooking" style={{ display: "flex", alignItems: "center", pointerEvents: myFilesData.length === 0 ? "none" : '' }}>

            <div className='customizeTab'>  <CSVLink filename={`${moment().format('MMMM_Do_YYYY_h_mm')}`} data={csvData}>CSV</CSVLink> </div>
            <div className='customizeTab'>
              <Workbook filename={`${moment().format('MMMM_Do_YYYY_h_mm')}.xlsx`} element={<span>Excel</span>}>
                <Workbook.Sheet data={excelArry} name="Sheet A">
                  <Workbook.Column label="Booking Date" value="bookingDate" />
                  <Workbook.Column label="PNR" value="recordLocator" />
                  <Workbook.Column label="Booking Status" value="description" />
                  <Workbook.Column label="Trip Type" value="tripType" />
                  <Workbook.Column label="Cabin Type" value="cabinType" />
                  <Workbook.Column label="Total Sell Amount" value="amount" />
                  <Workbook.Column label="Loyalty Point" value="layaltyPoint" />
                </Workbook.Sheet>
              </Workbook> </div>
          </div>

          <div>
            {selectOption === "customDate" && <div eventKey="customDate">
              <p className="dropditemp">
                <div className="bd-pi-input pi-input-date ">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      id="birth_date"
                      required
                      label="From"
                      margin="normal"
                      format="MM/dd/yyyy"
                      name="from"
                      value={customDate && customDate.from}
                      onChange={date => handleDateChange(date, "from")}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="bd-pi-input pi-input-date">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      id="birth_date"
                      required
                      label="To"
                      margin="normal"
                      format="MM/dd/yyyy"
                      name="to"
                      value={customDate && customDate.to}
                      onChange={date => handleDateChange(date, "to")}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="bd-pi-input pi-input-date ">
                  <button type="button" className="btn viewbuttonmyfile" onClick={() => getCustomDateData(filterOption, customDate)}>View</button>
                </div>
              </p>
            </div>}

          </div>

        </div>

      </div>

      <Bookingpnrlist myFilesData={myFilesData} filter="sortBy" filterOption={filterOption} date={datePeriod} bookingStatusCode={(k) => setBookingCode(k)} isResult={isResult} isLoading={isLoading} flightType={flightType}/>

    </>
  )
}
