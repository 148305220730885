import { useContext, useEffect,  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import authContext from "../../context/auth-context";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Alert } from "react-bootstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';


export default function Updateprofile(props:any) {
    const userInfo = useSelector((state:any) => state.userInfo);
    const loggedUsername = userInfo?.userData?.username
    const { orgId } = useContext(authContext);
    const [userId ,setUserId] = useState<any>('');
    const [ttsuserId ,setTtsuserId] = useState<any>('')
    const [firstName ,setFirstName] = useState<any>('')
    const [lastName ,setLastName] = useState<any>('')
    const [entityStatus ,setentityStatus] = useState<any>('')
    const [gender ,setGender] = useState<any>('Male')
    const [email ,setEmail] = useState<any>('')
    const [travelBusinessesId ,setTravelBusinessesId] = useState<any>('')
    const [contactNumber ,setContactNumber] = useState<any>('')
    const [countryCode ,setCountryCode] = useState<any>('')
    const [contactNumberAdditional ,setContactNumberAdditional] = useState<any>('')
    const [languagePreference ,setLanguagePreference] = useState<any>('EN')
    const [allowloyaltyPoints ,setAllowloyaltyPoints] = useState<any>('')
    const [allowManagerAccess ,setAllowManagerAccess] = useState<any>('')
    const [allowWhiteLabelWebsite ,setAllowWhiteLabelWebsite] = useState<any>('')
    const [freeTextAirExecutiveStatus ,setFreeTextAirExecutiveStatus] = useState<any>('')
    const [creationDate ,setCreationDate] = useState<any>('')
    const [transferDate ,setTransferDate] = useState<any>('')
    const [closingDate ,setClosingDate] = useState<any>('')
    const [freeTextLoyaltyPointsSpecialAgreement ,setFreeTextLoyaltyPointsSpecialAgreement] = useState<any>('')
    const [alert, setAlert] = useState({ variant: 'success', show: false, message: '' });
    
    const [isEdit ,setIsEdit] = useState<any>(false)

    const [agencyData , setAgencyData] =  useState([]);
    const [mobileCode , setMobileCode] =  useState('');
    const [agencyDataForId, setAgencyDataForId] = useState<any>([]);



       // FIRST API CALL 
       useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}/users/external?userId=${props.match.params.userId}&orgId=${orgId}`, {
            method: 'GET',
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(result =>{
                setUserId(result.username);
                setTtsuserId("")
                setFirstName(result.firstName)
                setLastName(result.lastName)
                setentityStatus(result.entityStatus)
                setGender(result.gender)
                setEmail(result.email)
                setTravelBusinessesId(result.travelBusinessesId)
                setContactNumber(result.contactNumber)
                setContactNumberAdditional(result.contactNumberAdditional)
                setLanguagePreference(result.languagePreference)
                setAllowloyaltyPoints(result.allowloyaltyPoints)
                setAllowManagerAccess(result.allowManagerAccess)
                setAllowWhiteLabelWebsite(result.allowWhiteLabelWebsite)
                setFreeTextAirExecutiveStatus(result.freeTextAirExecutiveStatus)
                setTransferDate(result.transferDate)
                setClosingDate(result.closingDate)
                setCreationDate(result.creationDate)
                setFreeTextLoyaltyPointsSpecialAgreement(result.freeTextLoyaltyPointsSpecialAgreement)    
                }
                 )
                 
            .catch(error => console.error("Error: ", error))
            .finally(() =>console.log());
    }, [orgId]);



       // VALIDATION MESSAGE 
       const [travelBusinessesIdError , setTravelBusinessesIdError] =  useState('');
       const [firstNameError , setFirstNameError] =  useState('');
       const [lastNameError , setLastNameError] =  useState('');
       const [contactNumberError , setContactNumberError] =  useState('');
       const [emailError , setEmailError] =  useState('');
       const [contactNumberAdditionalError , setContactNumberAdditionalError] =  useState('');
       const [freeTextAirExecutiveStatusError , setFreeTextAirExecutiveStatusError] =  useState('');
       const [freeTextLoyaltyPointsSpecialAgreementError , setFreeTextLoyaltyPointsSpecialAgreementError] =  useState('');
   
       // SET EMAIL ID 
       const setEmailId = (e:any) => {
           var regex = /^([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,9})$/;
           if(e.target.value.match(regex)){
               setEmailError('')
           } else {
               setEmailError("Please enter valid email.")
           }
           setEmail(e.target.value)
       }
   
       // SET CONTACT
       const setContact = (e:any) =>{
           if(e.target.value !== undefined){
           if(e.target.value.length > 10){
           setContactNumberError("Contact Number should be 10 digit.")
           setContactNumber(e.target.value)
           } else {
               setContactNumber(e.target.value)
               setContactNumberError("")
   
           }
       }
        }
       // VALIDATION FOR AGENT
       const validation = () =>{
           setTravelBusinessesIdError('');
           setFirstNameError('')
           setLastNameError('')
           setContactNumberError('')
           setContactNumberAdditionalError('')
           setFreeTextLoyaltyPointsSpecialAgreementError('')
           setFreeTextAirExecutiveStatusError('')

           if(agencyDataForId.length === 0){
               setTravelBusinessesIdError("Agency  can not be empty.")
           } else if(firstName === ''){
               setFirstNameError("First name can not be empty.")
           }  else if (lastName === ''){
               setLastNameError("Last name can not be empty.")
           }  else if (email === ''){
               setEmailError("Email can not be empty.")
           }   else if (contactNumber === ''){
               setContactNumberError("Contact Number can not be empty.")
           }   else if (contactNumberAdditional === ''){
               setContactNumberAdditionalError("Telphone Number can not be empty.")
           }   else if (freeTextAirExecutiveStatus === ''){
               setFreeTextAirExecutiveStatusError("Can not be empty.")
           }    else if (freeTextLoyaltyPointsSpecialAgreement === ''){
               setFreeTextLoyaltyPointsSpecialAgreementError("Can not be empty.")
           } else {
               onSubmit()
           }
       }
    async function onSubmit() {
        let corrId = `agent-users_external-update-`+ loggedUsername +"_"+ orgId +"-" + Date.now()
       let  jsonData = {
        "firstName": firstName,
        "lastName": lastName,
        "entityStatus": entityStatus,
     
        "contactNumber": mobileCode + contactNumber,
        "contactNumberAdditional": countryCode + contactNumberAdditional,
        "email": email,
    
        "languagePreference": languagePreference,
    
    
        "freeTextLogo": "This is loreum ispum",
        "freeTextAirExecutiveStatus": freeTextAirExecutiveStatus,
        "freeTextLoyaltyPointsSpecialAgreement": freeTextLoyaltyPointsSpecialAgreement,
    
        
        "travelBusinessesId": travelBusinessesId,
       // "travelBusinesses": [agencyDataForId && agencyDataForId[0].travelBusinessesId],
        "allowloyaltyPoints": allowloyaltyPoints,
    
    
        "transferDate":transferDate,
        "closingDate": closingDate,
        
    
    
        "gender": gender,
        
        "allowWhiteLabelWebsite": allowWhiteLabelWebsite,
        "allowManagerAccess": allowManagerAccess,
        
    
    }
        try {
            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/users/external?userId=${props.match.params.userId}&orgId=${orgId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    correlationid:corrId
                },
                body: JSON.stringify(jsonData),        
                })
                .then(response => response.json())
                .then(result =>{
                setIsEdit(false)
                swal({
                    text:"Profile updated successfully.",
                    icon: "success",
                  })
                // setAlert({ variant: 'success', show: true, message: 'Profile updated successfully' });
            })
           
        } catch (error) {
            swal({
                text:'Failed to update',
                icon: "error",
              })
            console.error(error);
           // setAlert({ variant: "danger", show: true, message: "Failed to update" });
        }
         setTimeout(function(){  setAlert({ ...alert, show: false }) }, 3000);
    }

    const getAutoSuggestAgency = (value:any) =>{
        if(value.length > 2){

            fetch(`${process.env.REACT_APP_BASE_URL}/travelBusinesses/autoSuggest?orgId=${orgId}&adAgencyCode=${value}`, {
                method: 'GET',
                redirect: 'follow'
            })
                .then(response => response.json())
                // console.log("response",response)
                .then(result =>{
                    result?.map((data:any)=>{
                      data['seacrhVariable'] = data.adAgencyCode + ' - ' + data.searchTravelBusinessesName
                    })
                    result.message === "No records found." ? setAgencyData([]) : setAgencyData(result)
                  })
                .catch(error => console.error("Error: ", error))
    }}
 
    useEffect(() => {
          let id = travelBusinessesId && travelBusinessesId;
        if(travelBusinessesId !==""){
            fetch(`${process.env.REACT_APP_BASE_URL}/travelBusinesses?travelBusinessesId=${id}&orgId=${orgId}&walletRequired=true`, {
                method: 'GET',
                redirect: 'follow',
              })
                .then((response) => response.json())
                .then((result: any) => {
                    result['seacrhVariable'] = result.adAgencyCode + ' - ' + result.searchTravelBusinessesName
                    setAgencyDataForId([{ ...result }]);
                })
                .catch((error) => console.error('error', error));    
            }
          }, [travelBusinessesId]);
        
          

      const getonFocusAgency = () =>{
        fetch(`${process.env.REACT_APP_BASE_URL}/travelBusinesses/all?orgId=${orgId}`, {
            method: 'POST',
            redirect: 'follow'
        })
            .then(response => response.json())
            // console.log("response",response)
            .then(result => {
                result['seacrhVariable'] = result.adAgencyCode + ' - ' + result.searchTravelBusinessesName
                setAgencyDataForId([{ ...result }]);
            })
            .catch(error => console.error("Error: ", error))
    }

    const setAgencyDataOne  = (value:any) =>{

        if(agencyDataForId.length === 0){
            setAgencyDataForId(value)
        } else {
            setAgencyDataForId([])
        }
    }

    return (
        <>
            <div className="cm-h1-head">
                <h1>Agent Profile Management</h1>
                <Link to="/agentprofile/true"><i className="fas fa-chevron-left"></i>Back to Listing</Link>
            </div>
            <Alert
          variant={alert.variant}
          show={alert.show}
          onClose={() => setAlert({ ...alert, show: false })}
          dismissible
        >
          {alert.message}
        </Alert>
            <div className="card cm-card">
                <div className="card-header">
                    <div className="cm-card-head-btn">
                        <h4 className="cm-card-head"> {isEdit ? "Edit" : "View"} Profile
                          {!isEdit ? <span className="cm-edit-link" onClick={()=>setIsEdit(!isEdit)} ><i className="fas fa-pen"></i> Edit</span> : <span style={{color:'red' ,cursor:'pointer'}} className="ml-5 text-danger" onClick={()=>setIsEdit(!isEdit)} ><i className="fas fa-times mr-5 " color="red"></i></span> }
                        </h4>
                    </div>
                </div>

                {isEdit ? 
                <div className="card-body">

                    <div className="row">
                        <div className="col-12">
                            <p className="cm-head-2">Agent Details</p>
                        </div>
                      
                        <div className="col-md-3 cm-col">
                        <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="small"
                        freeSolo
                        options={agencyData}
                        value={agencyDataForId}
                        style={{ background: 'white' }}
                        getOptionLabel={(option: any) => option?.seacrhVariable}
                        onChange={(event, value) => setAgencyDataOne(value)}
                        //defaultValue={[agencyData[0]]}
                        renderTags={(value: any, getTagProps: any) => {
                          if (value[0]?.message !== 'No records found.') {
                            return value.map((option: any, index: any) => (
                              <Chip
                                // color="primary"
                                // variant="outlined"
                                label={option?.adTravelAgencyName}
                                {...getTagProps({ index })}
                              />
                            ));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Agency"
                            // onFocus={()=>getonFocusAgency()}
                            onChange={(e)=>getAutoSuggestAgency(e.target.value)}
                            placeholder="Select Agency"
                          />
                        )}
                      />
                                          <span className="text-danger">{travelBusinessesIdError}</span>

                    </div>
                        {/* <div className="col-md-3 cm-col">
                            <label className="cm-head-label">User Id</label>
                            <input disabled value={userId} onChange={(e)=>setUserId(e.target.value)} className="form-control cm-input" placeholder="User Id" />
                        </div> */}
                        {/* <div className="col-md-3 cm-col">
                            <label className="cm-head-label">TTS user id</label>
                            <input value={ttsuserId} onChange={(e)=>setTtsuserId(e.target.value)} className="form-control cm-input" placeholder="TTS user id" />
                        </div> */}
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="cm-head-2">Personal Information</p>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">First Name</label>
                            <input value={firstName} onChange={(e)=>setFirstName(e.target.value)} className="form-control cm-input" placeholder="First Name" />
                            <span className="text-danger">{firstNameError}</span>

                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Last Name</label>
                            <input value={lastName} onChange={(e)=>setLastName(e.target.value)} className="form-control cm-input" placeholder="Last Name" />
                            <span className="text-danger">{lastNameError}</span>

                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Gender</label>
                            <select value={gender} onChange={(e)=>setGender(e.target.value)}  className="form-control cm-input-select">
                                <option value="Female">Female</option>
                                <option value="Male">Male</option>
                            </select>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Status</label>
                            <div className="cm-radio-inline">
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="Active"
                                    checked={entityStatus === '1' ? true : false}
                                    onChange={(e)=>setentityStatus("1")}
                                />
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="Inactive"
                                    checked={entityStatus === '0' ? true : false}
                                    onChange={(e)=>setentityStatus("0")}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Email address</label>
                            <input value={email} onChange={(e)=>setEmailId(e)} className="form-control cm-input" placeholder="Email address" />
                            <span className="text-danger">{emailError}</span>

                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Mobile</label>
                            <div className="input-group cm-text-county">
                            <select   value={mobileCode} onChange={(e)=>setMobileCode(e.target.value)} className="form-control cm-input-select">
                                    <option value="+1">CAN(+1)</option>
                                    <option value="+91">IND(+91)</option>
                            </select>
                                <input  value={contactNumber} onChange={(e)=>setContact(e)} className="form-control cm-input" placeholder="Mobile" />
                        </div>
                        <span className="text-danger">{contactNumberError}</span>

                        </div>
                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Additional telephone number</label>
                            <div className="input-group cm-text-county">
                                <select value={countryCode} onChange={(e)=>setCountryCode(e.target.value)} className="form-control cm-input-select">
                                    <option value="+1">CAN(+1)</option>
                                    <option value="+91">IND(+91)</option>
                                </select>
                                <input type="text" value={contactNumberAdditional} onChange={(e)=>setContactNumberAdditional(e.target.value)} aria-label="Text input with dropdown button" className="cm-input form-control" />

                            </div>

                        </div>
                        <p className="text-danger">{contactNumberAdditionalError}</p>

                        <div className="col-md-3 cm-col">
                            <label className="cm-head-label">Preferred language</label>
                            <select value={String(languagePreference).toUpperCase()} onChange={(e)=>setLanguagePreference(e.target.value)} className="form-control cm-input-select">
                                <option value="EN">English</option>
                                <option value="FR">French </option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="cm-head-2">Assign rights to agent</p>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Loyalty points promotion on air applies</label>
                            <div className="cm-radio-inline">
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    checked={allowloyaltyPoints}
                                    onChange={(e)=>setAllowloyaltyPoints(true)}
                                />
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="No"
                                    checked={allowloyaltyPoints === false}
                                    onChange={(e)=>setAllowloyaltyPoints(false)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Manager Access</label>
                            <div className="cm-radio-inline">
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    checked={allowManagerAccess}
                                    onChange={(e)=>setAllowManagerAccess(true)}
                                />
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="No"
                                    checked={allowManagerAccess === false}
                                    onChange={(e)=>setAllowManagerAccess(false)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">customized white label website</label>
                            <div className="cm-radio-inline">
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    checked={allowWhiteLabelWebsite}
                                    onChange={(e)=>setAllowWhiteLabelWebsite(true)}
                                />
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label="No"
                                    checked={allowWhiteLabelWebsite === false}
                                    onChange={(e)=>setAllowWhiteLabelWebsite(false)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Agent Air Executive Status</label>
                            <textarea
                            value={freeTextAirExecutiveStatus}
                            onChange={(e)=>setFreeTextAirExecutiveStatus(e.target.value)}
                            className="form-control cm-textarea"
                            />
                             <span className="text-danger">{freeTextAirExecutiveStatusError}</span>

                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Loyatly Points Special Agreements</label>
                            <textarea
                                value={freeTextLoyaltyPointsSpecialAgreement}
                                onChange={(e)=>setFreeTextLoyaltyPointsSpecialAgreement(e.target.value)}
                                className="form-control cm-textarea"
                            />
                        <span className="text-danger">{freeTextLoyaltyPointsSpecialAgreementError}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="cm-head-2">Profile creation</p>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Profile Creation date</label>
                            <input value={creationDate} type="date" className="form-control cm-input-date" disabled/>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Profile Closing date <span className="cm-primary-text">(only if applicable)</span></label>
                            <input type="date" value={closingDate} onChange={(e)=>setClosingDate(e.target.value)} className="form-control cm-input-date"/>
                        </div>
                        <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Profile Transfer date <span className="cm-primary-text">(To be discussed yet)</span></label>
                            <input   value={transferDate} onChange={(e)=>setTransferDate(e.target.value)} type="date" className="form-control cm-input-date"/>
                        </div>
                        {/* <div className="col-md-4 cm-col">
                            <label className="cm-head-label">Link to Travel Agency (Linked to only one agency)</label>
                            <input value={travelBusinessesId} onChange={(e)=>setTravelBusinessesId(e.target.value)} type="text" className="form-control cm-input"/>
                        </div> */}
                        <div className="col-12 cm-col text-right">
                            <Link to="/agentprofile/true"> <button  className="cm-secondary-btn mr-2" >Cancel</button></Link>
                            <button onClick={()=>validation()}  className="cm-primary-btn">Update Profile</button>
                        </div>
                    </div>

                </div>
                :
                <div className="card-body">

                <div className="row">
                    <div className="col-12">
                        <p className="cm-head-2">Agent Details</p>
                    </div>
                    <div className="col-md-3 cm-col">
                        <label className="cm-head-label">User Id</label>
                        <input disabled value={userId} onChange={(e)=>setUserId(e.target.value)} className="form-control cm-input" placeholder="User Id" />
                    </div>
                    <div className="col-md-3 cm-col">
                        <label className="cm-head-label">TTS user id</label>
                        <input disabled value={ttsuserId} onChange={(e)=>setTtsuserId(e.target.value)} className="form-control cm-input" placeholder="TTS user id" />
                    </div>
                </div>
                <div className="col-md-3 cm-col">
                <Autocomplete
                        multiple
                        disabled
                        freeSolo
                        id="size-small-outlined-multi"
                        size="small"
                        options={agencyData}
                        value={agencyDataForId}
                        style={{ background: 'white' }}
                        getOptionLabel={(option: any) => option.searchTravelBusinessesName}
                        onChange={(event, value) => setAgencyDataForId(value)}
                        // defaultValue={[top100Films[13]]}
                        renderTags={(value: any, getTagProps: any) => {
                          if (value[0]?.message !== 'No records found.') {
                            return value.map((option: any, index: any) => (
                              <Chip
                                // color="primary"
                                // variant="outlined"
                                label={option?.adTravelAgencyName}
                                {...getTagProps({ index })}
                              />
                            ));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Agency"
                            // onFocus={()=>getonFocusAgency()}
                            onChange={(e)=>getAutoSuggestAgency(e.target.value)}
                            placeholder="Select Agency"
                          />
                        )}
                      />
                    </div>
                <div className="row">
                    <div className="col-12">
                        <p className="cm-head-2">Personal Information</p>
                    </div>
                    <div className="col-md-3 cm-col">
                        <label className="cm-head-label">First Name</label>
                        <input disabled value={firstName} onChange={(e)=>setFirstName(e.target.value)} className="form-control cm-input" placeholder="First Name" />
                    </div>
                    <div className="col-md-3 cm-col">
                        <label className="cm-head-label">Last Name</label>
                        <input disabled value={lastName} onChange={(e)=>setLastName(e.target.value)} className="form-control cm-input" placeholder="Last Name" />
                    </div>
                    <div className="col-md-3 cm-col">
                        <label className="cm-head-label">Gender</label>
                        <select disabled value={gender} onChange={(e)=>setGender(e.target.value)}  className="form-control cm-input-select">
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                        </select>
                    </div>
                    <div className="col-md-3 cm-col">
                        <label className="cm-head-label">Status</label>
                        <div className="cm-radio-inline">
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                label="Active"
                                disabled
                                checked={entityStatus === '1' ? true : false}
                                onChange={(e)=>setentityStatus("1")}
                            />
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                label="Inactive"
                                disabled
                                checked={entityStatus === '0' ? true : false}
                                onChange={(e)=>setentityStatus("0")}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 cm-col">
                        <label className="cm-head-label">Email address</label>
                        <input disabled value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control cm-input" placeholder="Email address" />
                    </div>
                    <div className="col-md-3 cm-col">
                        <label className="cm-head-label">Mobile</label>
                        <div className="input-group cm-text-county">
                            <select disabled  value={mobileCode} onChange={(e)=>setMobileCode(e.target.value)} className="form-control cm-input-select">
                                    <option value="+1">CAN(+1)</option>
                                    <option value="+91">IND(+91)</option>
                            </select>
                        <input disabled value={contactNumber} onChange={(e)=>setContactNumber(e.target.value)} className="form-control cm-input" placeholder="Mobile" />
                        </div>
                    </div>
                    <div className="col-md-3 cm-col">
                        <label className="cm-head-label">Additional telephone number</label>
                        <div className="input-group cm-text-county">
                            <select disabled value={countryCode} className="form-control cm-input-select">
                                <option value="+1">CAN(+1)</option>
                                <option value="+91">IND(+91)</option>
                            </select>
                            <input disabled value={contactNumberAdditional} onChange={(e)=>setContactNumberAdditional(e.target.value)} aria-label="Text input with dropdown button" className="cm-input form-control" />
                        </div>
                    </div>
                    <div className="col-md-3 cm-col">
                        <label className="cm-head-label">Preferred language</label>
                        <select disabled value={String(languagePreference).toUpperCase()} onChange={(e)=>setLanguagePreference(e.target.value)} className="form-control cm-input-select">
                            <option value="EN">English</option>
                            <option value="FR">French </option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <p className="cm-head-2">Assign rights to agent</p>
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">Loyalty points promotion on air applies</label>
                        <div className="cm-radio-inline">
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                label="Yes"
                                disabled
                                checked={allowloyaltyPoints}
                                onChange={(e)=>setAllowloyaltyPoints(true)}
                            />
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                label="No"
                                disabled
                                checked={allowloyaltyPoints === false}
                                onChange={(e)=>setAllowloyaltyPoints(false)}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">Manager Access</label>
                        <div className="cm-radio-inline">
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                label="Yes"
                                disabled
                                checked={allowManagerAccess}
                                onChange={(e)=>setAllowManagerAccess(true)}
                            />
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                label="No"
                                disabled
                                checked={allowManagerAccess === false}
                                onChange={(e)=>setAllowManagerAccess(false)}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">customized white label website</label>
                        <div className="cm-radio-inline">
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                label="Yes"
                                disabled
                                checked={allowWhiteLabelWebsite}
                                onChange={(e)=>setAllowWhiteLabelWebsite(true)}
                            />
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                label="No"
                                disabled
                                checked={allowWhiteLabelWebsite === false}
                                onChange={(e)=>setAllowWhiteLabelWebsite(false)}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">Agent Air Executive Status</label>
                        <textarea
                        disabled
                        value={freeTextAirExecutiveStatus}
                        onChange={(e)=>setFreeTextAirExecutiveStatus(e.target.value)}
                        className="form-control cm-textarea"
                        />
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">Loyatly Points Special Agreements</label>
                        <textarea
                            disabled
                            value={freeTextLoyaltyPointsSpecialAgreement}
                            onChange={(e)=>setFreeTextLoyaltyPointsSpecialAgreement(e.target.value)}
                            className="form-control cm-textarea"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <p className="cm-head-2">Profile creation</p>
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">Profile Creation date</label>
                        <input value={creationDate} disabled type="date" className="form-control cm-input-date"/>
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">Profile Closing date <span className="cm-primary-text">(only if applicable)</span></label>
                        <input disabled type="date" value={closingDate} onChange={(e)=>setClosingDate(e.target.value)} className="form-control cm-input-date"/>
                    </div>
                    <div className="col-md-4 cm-col">
                        <label className="cm-head-label">Profile Transfer date <span className="cm-primary-text">(To be discussed yet)</span></label>
                        <input disabled  value={transferDate} onChange={(e)=>setTransferDate(e.target.value)} type="date" className="form-control cm-input-date"/>
                    </div>
                    {/* <div className="col-md-4 cm-col">
                        <label className="cm-head-label">Link to Travel Agency (Linked to only one agency)</label>
                        <input disabled value={travelBusinessesId} onChange={(e)=>setTravelBusinessesId(e.target.value)} type="text" className="form-control cm-input"/>
                    </div> */}
                </div>
            </div>
            }

            </div>
        </>
    )
}

